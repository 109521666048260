import React, { useEffect, useState } from "react";
import _get from "lodash.get";
import { Link } from "gatsby";
import { format } from "date-fns";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import contentfulClient from "../../utils/contentful-helper";
import Layout from "../../components/layout";
import "../../styles/pages/properties.scss";

export default function EventsList() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(_get(data, "skip", 0));
  const { items, total, skip, limit } = data;

  const getData = () => {
    setLoading(true);
    return contentfulClient
      .getEntries({
        content_type: "events",
        order: "-fields.happenedOn",
        skip: page * 10,
        limit: 10,
      })
      .then((d) => {
        console.log(d);
        setData({ ...d });
      })
      .catch((err) => {
        setData(null);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout title="Actions">
      <h1 className="page-title">Events</h1>
      {loading ? (
        <div className="loading">
          <p>Loading, please wait!</p>
        </div>
      ) : (
        <div className="actions-list">
          <ul>
            {items?.map((d) => {
              const thumb = _get(d, "fields.gallery[0].fields.file.url", "");
              const timeStamp = _get(d, "fields.happenedOn", "");
              const title = _get(d, "fields.title", "");
              const description = _get(d, "fields.description", "");
              return (
                <li className="actions-list-item">
                  <Link to={`/events/details?id=${_get(d, "sys.id")}`}>
                    <div className="action-thumb">
                      <img src={thumb} alt="property-photo" />
                    </div>
                    <div className="action-info">
                      <h3>{title}</h3>
                      <p>
                        <span>{format(new Date(timeStamp), "d-LLL-y")}</span>
                      </p>
                      <p className="description">
                        {documentToPlainTextString(description)}
                      </p>
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>
          <div className="property-actions">
            <button onClick={() => setPage(page - 1)} disabled={page <= 0}>
              Prev
            </button>
            <button
              onClick={() => setPage(page + 1)}
              disabled={total < limit + skip}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </Layout>
  );
}
